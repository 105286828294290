import React from 'react'
import { FaEdge } from 'react-icons/fa'
import { DiStreamline } from 'react-icons/di'
import { GrUserExpert } from 'react-icons/gr'

const Empowering = () => {
	return (
		<div className='Empowering' id='Expertise'>
			<div className='container text-center mt-5'>
				<div className='row'>
					<h1 className='mt-5 pt-5 heading'>
						Empowering <br /> Businesses with{' '}
					</h1>
					<p className='para'>Elevating Customer Satisfaction Through Seamless</p>
				</div>
				<div className='row mt-3'>
					<div className='col-md-4 mb-5 pb-5'>
						<DiStreamline size={50} color='white' className='mb-3' />
						<h4 className='heading'>Streamlined Processes</h4>
						<p className='para'>Our team of experts works tirelessly to design and implement tailored call center solutions that meet your specific business needs, ensuring exceptional customer service and operational efficiency. </p>
					</div>
					<div className='col-md-4 mb-5'>
						<FaEdge size={50} color='white' className='mb-3' />
						<h4 className='heading'>Cutting-Edge</h4>
						<p className='para'>At the heart of our call center solutions lies a robust and flexible technology stack that enables us to adapt to the ever-evolving needs of our clients, ensuring seamless communication, efficient workflow management.</p>{' '}
					</div>
					<div className='col-md-4 mb-5'>
						<GrUserExpert size={50} color='white' className='mb-3' />
						<h4 className='heading'> Unparalleled Expertise</h4>
						<p className='para'>With years of experience in the call center industry, our team of seasoned professionals possesses the expertise and insights necessary to deliver support and innovative solutions tailored to meet the unique demands of client.</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Empowering
