import React from 'react'

const Location = () => {
	return (
		<div>
			<div className='container-fluid'>
				<div className='row'>
					<iframe
						src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3443.331582277895!2d-97.75747352453085!3d30.34152500443421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cae2feb064b3%3A0xfbcc19243aa6ddc0!2sSyner%20Supplements%2C%205900%20Balcones%20Dr%2C%20Austin%2C%20TX%2078731%2C%20USA!5e0!3m2!1sen!2s!4v1729708328078!5m2!1sen!2s'
						width='600'
						height='450'
						style={{ border: '0' }}
						allowfullscreen=''
						loading='lazy'
						referrerpolicy='no-referrer-when-downgrade'
					></iframe>
				</div>
				
			</div>
		</div>
	)
}

export default Location
