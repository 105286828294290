import React, { useState, useEffect } from 'react'
import { FaArrowCircleUp } from 'react-icons/fa'

const BottomFotter = () => {
	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 100) {
				setIsVisible(true)
			} else {
				setIsVisible(false)
			}
		}

		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const topFunction = () => {
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	}

	return (
		<div className='BottomFooter'>
			<div className='bg-white'>
				<div className={`copy-right-wrapper width  w-full  text-sm text-center my-3 h-20 flex items-center justify-between mb-24 copy-right-bottom `}>
					<div className={`copy-right  margin-btm heading  `}>
						Copyright © 2024
						<span>
							&nbsp;
							<a>
								<span className='cursor-pointer border-b border-black border-solid'>BlackBridgeSolutions.com.</span>
							</a>
							&nbsp;
						</span>
						All Right Reserved.
					</div>

					{isVisible && <FaArrowCircleUp id='myBtn' onClick={topFunction} title='Go to top' color='black' size={35} style={{ cursor: 'pointer' }} />}
				</div>
			</div>
		</div>
	)
}

export default BottomFotter
