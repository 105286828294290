import React from 'react'
import CST from '../../Assests/customersupport.jpg'

const Customersupport = () => {
	return (
		<div className='Customersupport' id='CustomerSupport'>
			<div className='container'>
				<div className='row'>
					<h1 className='text-center mt-4 mb-0 taxi-heading'>CUSTOMER SUPPORT </h1>
				</div>
				<div className='row'>
					<div className='col-md-6'>
						<img src={CST} className='img-fluid' alt='' />
					</div>
					<div className='col-md-6 mt-5 pt-5'>
						<p className='taxi-para mt-5'>
							At <strong>Black Bridge Solutions</strong>, we specialize in delivering exceptional customer service experiences for businesses of all sizes. Our team of highly trained and dedicated professionals are passionate about providing top-notch support to your customers, ensuring their
							satisfaction and loyalty. With our state-of-the-art technology and robust processes, we offer a range of customer service solutions tailored to meet your unique needs. <br />
							<br /> From phone and email support to live chat and social media management, we've got you covered. By outsourcing your customer service to us, you can focus on your core business while we handle the rest, resulting in improved customer retention, increased efficiency, and reduced
							costs. Let us help you take your customer service to the next level.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Customersupport
