import React from 'react'
import Logo from '../../Assests/logo1.png'
import { IoIosCall } from 'react-icons/io'

const Header = () => {
	return (
		<div className='Header'>
			<nav className='navbar navbar-expand-lg bg-body-tertiary'>
				<div className='container '>
					<a className='navbar-brand ' href='#'>
						<img className='img-fluid header-img' style={{ width: '120px', height: '120px' }} src={Logo} alt='' />
					</a>
					<button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
						<span className='navbar-toggler-icon' />
					</button>

					<div className='collapse navbar-collapse ' id='navbarSupportedContent'>
						<ul className='navbar-nav mx-auto mb-2 mb-lg-0'>
							<li className='nav-item'>
								<a className='nav-link active menuitem' aria-current='page' href='#home'>
									Home
								</a>
							</li>
							&nbsp;
							<li className='nav-item'>
								<a className='nav-link active menuitem' aria-current='page' href='#Expertise'>
									Expertise
								</a>
							</li>
							&nbsp;
							<li className='nav-item'>
								<a className='nav-link active menuitem' aria-current='page' href='#Taxidispatch'>
									Services
								</a>
							</li>
							&nbsp;
							<li className='nav-item'>
								<a className='nav-link active menuitem' aria-current='page' href='#OurAchievements'>
									Our Achievements
								</a>
							</li>
							&nbsp;
							<li className='nav-item'>
								<a className='nav-link active menuitem' aria-current='page' href='#faqs'>
									FAQ's
								</a>
							</li>
							&nbsp;
							<li className='nav-item'>
								<a className='nav-link active menuitem' aria-current='page' href='#contactus'>
									Contact Us
								</a>
							</li>
						</ul>
						<form className='d-flex' role='search'>
							<a href='tel:+923393962483' className='contactus-btn'>
								Call Now <IoIosCall className='mt-1' />
							</a>
						</form>
					</div>
				</div>
			</nav>
		</div>
	)
}

export default Header
