import React from 'react'
import Taxi from '../../Assests/taxi.jpg'

const Taxidispatch = () => {
	return (
		<div className='Taxidispatch' id='Taxidispatch'>
			<div className='container'>
				<div className='row text-center my-4'>
					<h1 className='taxi-heading mt-5 '>TAXI DISPATCH </h1>
				</div>
				<div className='row my-5'>
					<div className='col-md-6 mt-3'>
						<p className='taxi-para'>
							Our taxi dispatch services are designed to help transportation companies like yours stay ahead of the curve. With our advanced dispatch software and experienced team, we can help you increase efficiency, reduce costs, and improve customer satisfaction. Our services include:
						</p>
						<ul className='taxi-para'>
							<li> 24/7 call taking and ride scheduling</li>
							<li> Real-time dispatch and driver management</li>
							<li> Automated ride assignment and routing</li>
							<li> Live tracking and status updates</li>
							<li>Customizable reporting and analytics</li>
						</ul>
						<p className='taxi-para'>
							By outsourcing your taxi dispatch to us, you can free up resources to focus on growing your business, while we handle the logistics of getting passengers where they need to go. Contact us today to learn more about how our taxi dispatch services can benefit your transportation company!
						</p>
					</div>
					<div className='col-md-6'>
						<img src={Taxi} className='img-fluid hero-img' alt='' />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Taxidispatch
