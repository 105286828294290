import React from 'react'

const Faqs = () => {
	return (
		<div className='Faqs' id='faqs'>
			<div className='container my-5'>
				<h1 className='text-center mb-4 heading'>Frequently Asked Question's</h1>
				<div className='accordion' id='accordionPanelsStayOpenExample'>
					<div className='accordion-item'>
						<h2 className='accordion-header' id='panelsStayOpen-headingOne'>
							<button className='accordion-button faqs-btn heading' type='button' data-bs-toggle='collapse' data-bs-target='#panelsStayOpen-collapseOne' aria-expanded='true' aria-controls='panelsStayOpen-collapseOne'>
								What makes Black Bridge Solutions customer support team stand out?
							</button>
						</h2>
						<div id='panelsStayOpen-collapseOne' className='accordion-collapse collapse ' aria-labelledby='panelsStayOpen-headingOne'>
							<div className='accordion-body heading'>Our team of highly trained and experienced customer support professionals is dedicated to delivering top-notch service, ensuring your customers receive the best possible care.</div>
						</div>
					</div>
					<div className='accordion-item'>
						<h2 className='accordion-header' id='panelsStayOpen-headingTwo'>
							<button className='accordion-button collapsed faqs-btn heading' type='button' data-bs-toggle='collapse' data-bs-target='#panelsStayOpen-collapseTwo' aria-expanded='false' aria-controls='panelsStayOpen-collapseTwo'>
								How does Black Bridge Solutions ensure the customer support solutions are tailored to my business?
							</button>
						</h2>
						<div id='panelsStayOpen-collapseTwo' className='accordion-collapse collapse' aria-labelledby='panelsStayOpen-headingTwo'>
							<div className='accordion-body heading'>We take the time to understand your business goals and create customized support strategies to meet your specific requirements, ensuring our solutions are perfectly aligned with your needs. </div>
						</div>
					</div>
					<div className='accordion-item'>
						<h2 className='accordion-header' id='panelsStayOpen-headingThree'>
							<button className='accordion-button collapsed faqs-btn heading' type='button' data-bs-toggle='collapse' data-bs-target='#panelsStayOpen-collapseThree' aria-expanded='false' aria-controls='panelsStayOpen-collapseThree'>
								Can Black Bridge Solutions handle support for a global customer base?
							</button>
						</h2>
						<div id='panelsStayOpen-collapseThree' className='accordion-collapse collapse' aria-labelledby='panelsStayOpen-headingThree'>
							<div className='accordion-body heading'>Yes, our agents are proficient in multiple languages, ensuring seamless communication and support for your global customer base. </div>
						</div>
					</div>
					<div className='accordion-item'>
						<h2 className='accordion-header' id='panelsStayOpen-headingFour'>
							<button className='accordion-button collapsed faqs-btn heading' type='button' data-bs-toggle='collapse' data-bs-target='#panelsStayOpen-collapseFour' aria-expanded='false' aria-controls='panelsStayOpen-collapseFour'>
								Is Black Bridge Solutions available to provide customer support around the clock?
							</button>
						</h2>
						<div id='panelsStayOpen-collapseFour' className='accordion-collapse collapse' aria-labelledby='panelsStayOpen-headingFour'>
							<div className='accordion-body heading'>Absolutely. Our support services are available 24/7, so your customers can receive help whenever they need it. </div>
						</div>
					</div>

					<div className='accordion-item'>
						<h2 className='accordion-header' id='panelsStayOpen-headingFive'>
							<button className='accordion-button collapsed faqs-btn heading' type='button' data-bs-toggle='collapse' data-bs-target='#panelsStayOpen-collapseFive' aria-expanded='false' aria-controls='panelsStayOpen-collapseFive'>
								What kind of technology does Black Bridge Solutions use to enhance customer support?
							</button>
						</h2>
						<div id='panelsStayOpen-collapseFive' className='accordion-collapse collapse' aria-labelledby='panelsStayOpen-headingFive'>
							<div className='accordion-body heading'>We leverage state-of-the-art tools and software to enhance efficiency, accuracy, and customer satisfaction in our support services. </div>
						</div>
					</div>

					<div className='accordion-item'>
						<h2 className='accordion-header' id='panelsStayOpen-headingSix'>
							<button className='accordion-button collapsed faqs-btn heading' type='button' data-bs-toggle='collapse' data-bs-target='#panelsStayOpen-collapseSix' aria-expanded='false' aria-controls='panelsStayOpen-collapseSix'>
								Can Black Bridge Solutions scale their support services as my business grows?
							</button>
						</h2>
						<div id='panelsStayOpen-collapseSix' className='accordion-collapse collapse' aria-labelledby='panelsStayOpen-headingSix'>
							<div className='accordion-body heading'>Yes, our flexible support models adapt to your evolving business needs, ensuring consistent service quality no matter how much your business grows. </div>
						</div>
					</div>

					<div className='accordion-item'>
						<h2 className='accordion-header' id='panelsStayOpen-headingSeven'>
							<button className='accordion-button collapsed faqs-btn heading' type='button' data-bs-toggle='collapse' data-bs-target='#panelsStayOpen-collapseSeven' aria-expanded='false' aria-controls='panelsStayOpen-collapseSeven'>
								How can Black Bridge Solutions help reduce operational costs for my business?
							</button>
						</h2>
						<div id='panelsStayOpen-collapseSeven' className='accordion-collapse collapse' aria-labelledby='panelsStayOpen-headingSeven'>
							<div className='accordion-body heading'>Our BPO solutions are cost-effective, helping to reduce operational costs without compromising on the quality of customer support. </div>
						</div>
					</div>

					<div className='accordion-item'>
						<h2 className='accordion-header' id='panelsStayOpen-headingEight'>
							<button className='accordion-button collapsed faqs-btn heading' type='button' data-bs-toggle='collapse' data-bs-target='#panelsStayOpen-collapseEight' aria-expanded='false' aria-controls='panelsStayOpen-collapseEight'>
								How does Black Bridge Solutions ensure continuous improvement in their customer support services?
							</button>
						</h2>
						<div id='panelsStayOpen-collapseEight' className='accordion-collapse collapse' aria-labelledby='panelsStayOpen-headingEight'>
							<div className='accordion-body heading'>We regularly assess and refine our processes to ensure optimal performance and customer satisfaction, consistently working towards improving our services. </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Faqs
