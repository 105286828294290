import React from 'react'
import bpo1 from '../../Assests/bpo1.jpg'
import { IoIosArrowDown } from 'react-icons/io'

const Heropage = () => {
	return (
		<div className='Heropage' id='home'>
			<div className='container'>
				<div className='row heading-padding'>
					<div className='col-md-6 mt-3'>
						<h1 className='mt-3 heading'>
							Welcome to <br /> Black Bridge Solutions
						</h1>
						<p className='mt-2 para'>
							Where we believe in excellence in team and execution. We're a leading BPO company driven by a passion for delivering exceptional service, innovative solutions, and unparalleled support. Our mission is to empower businesses to achieve their full potential, and we're committed to helping
							our clients succeed through our expertise, cutting-edge technology, and customer-centric approach. Explore our website to learn more about our comprehensive range of services and how we can help you transform your business.
						</p>
						<button className='contactus-btn mt-2'>
							Explore Our Services <IoIosArrowDown className='mt-1' color='white' />
						</button>
					</div>
					<div className='col-md-6'>
						<img className='img-fluid hero-img mt-4  ' src={bpo1} alt='' />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Heropage
