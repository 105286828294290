import React from 'react'
import { ImHappy2 } from 'react-icons/im'
import { MdOutlineSentimentSatisfiedAlt } from 'react-icons/md'
import { MdOutlineWork } from 'react-icons/md'

const CustomerSatisfaction = () => {
	return (
		<div className='customersatisfaction' id='OurAchievements'>
			<div className='container text-center'>
				<div>
					<h2 className='heading pt-5'>Our Achievements</h2>
					<p className='para'>We are proud of our long-standing commitment to excellence and customer satisfaction. Here are some key highlights:</p>
				</div>
				<div className='row mt-3'>
					<div className='col-md-4 my-5'>
						<MdOutlineWork color='white' size={50} />
						<h3 className='heading'>Experience</h3>
						<p className='para'>With over a decade of expertise, we ensure top-notch service.</p>
						<h1 className='heading'>15+</h1>
						<p className='para'>Years of Experience</p>
					</div>
					<div className='col-md-4 my-5'>
						<MdOutlineSentimentSatisfiedAlt color='white' size={50} />
						<h3 className='heading'>Client</h3>
						<p className='para'>Our clients trust us and we have built lasting relationships.</p>
						<h1 className='heading'>3k+</h1>
						<p className='para'>Happy Clients</p>
					</div>
					<div className='col-md-4 my-5'>
						<ImHappy2 color='white' size={50} />
						<h3 className='heading'>Satisfaction</h3>
						<p className='para'>
							Our dedication to customer satisfaction is <br /> unparalleled.
						</p>
						<h1 className='heading'>100%</h1>
						<p className='para'>Satisfaction</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CustomerSatisfaction
