import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import './App.scss'
import Header from './Components/Layouts/Header'
import Footer from './Components/Layouts/Footer'
import Faqs from './Components/Utils/Faqs'
import Location from './Components/Elements/Location'
import Heropage from './Components/Elements/Heropage'
import Empowering from './Components/Elements/Empowering'
import BottomFotter from './Components/Layouts/BottomFotter'
import CustomerSatisfaction from './Components/Elements/CustomerSatisfaction'
import Taxidispatch from './Components/Elements/Taxidispatch'
import Customersupport from './Components/Elements/Customersupport'
import Aboutus from './Components/Elements/Aboutus'

function App() {
	return (
		<div>
			<Header />
			<Heropage />
			<Empowering />
			<Taxidispatch />
			<Customersupport />
			<CustomerSatisfaction />
			<Faqs />
			<Aboutus />
			<Location />
			<Footer />
			<BottomFotter />
		</div>
	)
}

export default App
